@import "color/green"; // Adjust based on your selected theme color

#isso-thread {
    color: var(--color);
    font-family: monospace;
}

#isso-thread input,
#isso-thread textarea {
    background-color: var(--background);
    color: var(--color);
    border: 1px solid var(--border-color);
    padding: 5px;
    font-size: 1em;
}

#isso-thread button {
    background: transparent;
    border: 1px solid var(--border-color);
    color: var(--border-color);
    padding: 8px 12px;
    font-size: 1em;
    cursor: pointer;
    transition: background 0.2s ease-in-out;

    &:hover {
        background: var(--border-color);
        color: var(--background);
    }
}

.isso-comment {
    border: 1px solid var(--border-color);
    padding: 10px;
    margin-bottom: 10px;
    background-color: var(--background);
}

.isso-avatar {
    display: none; // Hide avatars if not needed
}

.isso-comment-header {
    color: var(--accent);
    font-weight: bold;
}

.isso-comment-footer {
    color: var(--color);
    font-size: 0.8em;
}

/* Links in comments */
.isso-comment a {
    color: var(--accent);
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
#isso-thread {
    max-width: 100%;
    margin: 0 auto;
    padding: 10px;
}

#isso-thread input,
#isso-thread textarea {
    width: 100%;
    box-sizing: border-box; // Ensures padding doesn't affect width
    margin-bottom: 8px;
}

#isso-thread button {
    width: auto;
    display: inline-block;
    margin-top: 5px;
}

.isso-comment {
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
}

.isso-comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.isso-comment-footer {
    text-align: right;
}
.isso-comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px; // Adds spacing between elements
}

.isso-comment-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap; // Ensures elements wrap properly if needed
}

.isso-comment-footer a {
    margin-right: 10px; // Ensures links are spaced correctly
}

.isso-comment-footer span {
    white-space: nowrap; // Prevents elements from collapsing together
}
